<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
	import {mapGetters} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
			
			}
		},
		mounted(){
			if(localStorage.getItem('userinfo')){
				let uinfo = JSON.parse(localStorage.getItem('userinfo'))
				this.$store.dispatch('verify',{token:uinfo.token}).then(result=>{
					if(result.code == 200){
						sessionStorage.setItem('adr',result.data)
						this.states.userinfo = JSON.parse(localStorage.getItem('userinfo'))
						this.$store.dispatch('OTHERINFO')   // 用户额外信息
						this.states.islogin = true;
					}else if(result.code == 300){
						localStorage.clear(); //清空本地缓存
						this.states.LoginShow = false
						this.loginForm.code = ''
					}
				})	
			}
		},
		methods:{
			
		}
	}
</script>
	
<style>
	
	html {
		background-color: rgba(255, 255, 255, 0.5); /* 设置背景色，可以根据需要调整颜色和透明度 */
		backdrop-filter: blur(10px); /* 设置毛玻璃效果的模糊程度，可以根据需要调整像素值 */
	}
	#app{
		font-family: 'Avenir', 'Helvetica', 'Arial', 'sans-serif',
		color #333;
		height: 100vh;
		width: 1300px;
		margin: 0 auto;
	}
	::-webkit-scrollbar {
		width: 0px;
	}
	::-webkit-scrollbar-thumb {
		border-radius: 7px;
		-webkit-box-shadow: inset 0 0 5px rgba(255,103,22, 0.5);
		background: rgba(255,103,22, 0.5);
	}
	
	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 1.0);
		border-radius: 0;
		background: rgba(207, 207, 207, 0.2);
	} 
body{
	padding: 0px;
	margin: 0px;
	background: url('./assets/bg1.png'); 
}
.family-black{
		font-family:"SimHei"
	}
.family-song{
	font-family:"SimSun"
}
.family-our{
	font-family:"KaiTi"
}


	
</style>
