/*
	全局接口
*/ 
const ApiRootUrl  = 'https://www.dsvi.com:3010/';
// let api = require('@/utils/api');								//  全局接口配置
export const ServerEditorFile = ApiRootUrl + 'ServerEditorFile';	//  文件上传	
export const ServerIconFile = ApiRootUrl + 'ServerIconFile';		//  Icon上传	
export const Login = ApiRootUrl + 'Login';							//  验证码登录	
export const RegCode = ApiRootUrl + 'RegCode';						//  发送验证码		
export const verify = ApiRootUrl + 'verify';						//  校验登录是否过期	
export const WeChatQR = ApiRootUrl + 'WeChatQR';					//  小程序二维码登录	
export const verifyQR = ApiRootUrl + 'verifyQR';					//  校验是否扫码	
export const otherinfo = ApiRootUrl + 'otherinfo';					//  用户额外信息	
export const allcourse = ApiRootUrl + 'allcourse';					//  课程列表
export const courseInfo = ApiRootUrl + 'courseInfo';				//  课程详情
export const allblog = ApiRootUrl + 'allblog';						//  博客列表
export const addCollect = ApiRootUrl + 'addCollect';				//  添加到收藏夹
export const delCollect = ApiRootUrl + 'delCollect';				//  移出收藏夹
export const Collect = ApiRootUrl + 'Collect';						//  收藏夹
export const ModifyUser = ApiRootUrl + 'ModifyUser';				//  修改用户名称
export const Balance = ApiRootUrl + 'Balance';						//  流水细明
export const WxAddQr = ApiRootUrl + 'WxAddQr';						//  充值二维码
export const createcourse = ApiRootUrl + 'createcourse';			//  创建课程
export const PutSection = ApiRootUrl + 'PutSection';				//  章节入库
export const GetCourses = ApiRootUrl + 'GetCourses';				//  课程列表
export const GetSection = ApiRootUrl + 'GetSection';				//  课程章节
export const UpCourses = ApiRootUrl + 'UpCourses';					//  更新课程
export const UpSection = ApiRootUrl + 'UpSection';					//  更新章节
export const AddSection = ApiRootUrl + 'AddSection';				//  添加章节
export const PayListen = ApiRootUrl + 'PayListen';					//  支付监听
export const PayMember = ApiRootUrl + 'PayMember';					//  学员认证
export const DelOrder = ApiRootUrl + 'DelOrder';					//  订单删除
export const authCourse = ApiRootUrl + 'authCourse';				//  课程鉴权
export const authCourseIng = ApiRootUrl + 'authCourseIng';			//  课程鉴权+学时记录
export const GetMyLogPlay = ApiRootUrl + 'GetMyLogPlay';			//  播放历史记录
export const DelMyLogPlay = ApiRootUrl + 'DelMyLogPlay';			//  删除 历史记录
export const GetMyCourses = ApiRootUrl + 'GetMyCourses';			//  获取我的课程
export const GetUserAll = ApiRootUrl + 'GetUserAll';				//  获取所有用户信息
export const GetUser = ApiRootUrl + 'GetUser';						//  获取用户信息
export const PutBlog = ApiRootUrl + 'PutBlog';						//  Blog发布
export const GetBlogAll = ApiRootUrl + 'GetBlogAll';				//  博客列表
export const ModifyBlog = ApiRootUrl + 'ModifyBlog';				//  博客更新
export const DiscussSay = ApiRootUrl + 'DiscussSay';				//  评论
export const GetCategoryList = ApiRootUrl + 'GetCategoryList';		//  课程分类列表
export const ModifyCategory = ApiRootUrl + 'ModifyCategory';		//  修改新增、课程分类
export const GetcourseCla = ApiRootUrl + 'GetcourseCla';			//  获取指定分类的课程
export const AllSearch = ApiRootUrl + 'AllSearch';					//  全局搜索

export const AmountPlay = ApiRootUrl + 'AmountPlay';					//  全局搜索
