import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import {timesDate,timestampToDate,OCURSECONTENT,timestampToTimes,webadr} from '@/utils/fun'
import {PostFile,post} from '@/utils/request'
Vue.prototype.$post = post
Vue.prototype.$PostFile = PostFile
Vue.prototype.$timesDate = timesDate
Vue.prototype.$timestampToDate = timestampToDate
Vue.prototype.$timestampToTimes = timestampToTimes
Vue.prototype.$OCURSECONTENT = OCURSECONTENT
Vue.prototype.$ip = webadr

// 引入播放器
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer) 

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
