// 获取地址
exports.webadr = function(){
	return ''
	// return binaryToString(insertSpaces(sessionStorage.getItem('adr')))
}
function binaryToString(binaryStr) {
	if(binaryStr){
		var binaryArray = binaryStr.split(' ');
		var resultString = '';
		for (var i = 0; i < binaryArray.length; i++) {
			var binaryChar = binaryArray[i];
			var charCode = parseInt(binaryChar, 2);
			var char = String.fromCharCode(charCode);
			resultString += char;
		}
		return resultString;
	}else{
		return binaryStr
	}
}

function insertSpaces(binaryStr) {
	if(binaryStr){
		var regex = /.{8}/g;
		var stringWithSpaces = binaryStr.replace(regex, '$& ');
		return stringWithSpaces.trim();
	}else{
		return binaryStr
	}
}


exports.verifyPhone = function(e){
    let A = /^1[3456789]\d{9}$/;
    return A.test(e)
}

exports.timesDate = function(timestamp) {
	timestamp = timestamp.length == 10?timestamp * 1000:timestamp
	var date = new Date(Number(timestamp));
	var Y = date.getFullYear() + '年';
	var M =  date.getMonth() + 1+ '月';
	var D = date.getDate() + '日';
	return M + D;
}

exports.timestampToDate = function(timestamp) {
	timestamp = timestamp.length == 10?timestamp * 1000:timestamp
	var date = new Date(Number(timestamp));
	var Y = date.getFullYear() + '年';
	var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月';
	var D = date.getDate() + '日';
	return Y + M + D;
}

exports.timestampToTime = function(timestamp) {
	if(timestamp){
		var date = new Date(Number(timestamp));
		var Y = date.getFullYear() + '-';
		var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
		var D = date.getDate() + ' ';
		var h = date.getHours() + ':';
		var m = date.getMinutes() + ':';
		var s = date.getSeconds();
		return Y + M + D + h + m + s;
	}else{
		return '无'
	}
}

exports.timestampToTimes = function(timestamp) {
	if(timestamp){
		timestamp = timestamp.length == 10?timestamp * 1000:timestamp
		var date = new Date(Number(timestamp));
		var Y = date.getFullYear() + '-';
		var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
		var D = (date.getDate() + 1 < 10 ? '0' + (date.getDate() + 1) : date.getDate() + 1) + ' ';
		var h = (date.getHours() + 1 < 10 ? '0' + (date.getHours() + 1) : date.getHours() + 1) + ':';
		var m = (date.getMinutes() + 1 < 10 ? '0' + (date.getMinutes() + 1) : date.getMinutes() + 1) ;
		// var s = (date.getSeconds() + 1 < 10 ? '0' + (date.getSeconds() + 1) : date.getSeconds() + 1) ;
		return Y + M + D + h + m;
	}else{
		return '无'
	}
}


// 格式化课程章节表
exports.OCURSECONTENT = function(e) {
	let arraysay = [],arr = [],cat = [],arrine = [],obj = {}
	e.forEach(item=>{
		cat.push(item.chapter)
	})
	arrine = Array.from(new Set(cat))
	//播放时长数组
	let timelengths = [],o = 0;
	arrine.forEach(item=>{
		e.forEach(list=>{
			if(list.chapter == item){
				list.show = false
				timelengths.push(list.timelength.split(/[分秒]/))
				arr.push(list)
				o ++;
			}
		})
		arraysay.push(arr)
		arr = []
	})
	let suour = 0
	//计算章节数  
	obj.chapternumber = arraysay.length
	obj.sectionnumber = o
	//计算时长总和
	timelengths.forEach(item=>{
		suour += parseInt(item[0]*60) + parseInt(item[1]===''?0:item[1])	
	})
	suour = suour/60/60
	// 总时长
	obj.timezong = suour.toFixed(2)
	obj.chapter = arraysay
	return obj	
}