import axios from 'axios'
import {MessageBox,Message,Notification} from 'element-ui'
import router from '@/router'
import store from '@/store'
let api = require('@/utils/api');					// 全局接口配置
import {webadr} from '@/utils/fun'

axios.interceptors.request.use(function(config) {
	if (localStorage.getItem("userinfo") != null) {
		let token = JSON.parse(localStorage.getItem("userinfo")).token 				// 把token加入到默认请求参数中
		config.headers.common['token'] = JSON.stringify({token:token,API:config.url.split("/")[config.url.split("/").length-1]})
	}
	return config
}, function(error) {
	return Promise.reject(error);
})
// POST 请求
export function post(obj) { 							// 调用时参数为一个对象
	return new Promise((resolve, reject) => { 			// 穿件Promise实例
		axios({ 										// 单例js 不允要this                          
			method: "POST", 							// 请求方式
			url: obj.url, 								// 请求地址
			data: obj.data 								// 请求参数
		}).then(res => { 								// 请求成功操作
			if (obj.IS !== false) {						// 是否需要弹框提示
				successState(res) 						// 调用弹框封装
			}
			resolve(res) 								// 新Promise对象实例（成功）
		}).catch(err => { 								// 请求失败操作
			let status = err.response.status; 			// 错误状态码
			if(status){
				switch (status) {
					case 400: 								// 状态码400
						Message({ 							// 调用message组件
							message: "数据格式错误，请重新填写！",
							type: "error", 					// 组件类型
							showClose: true 				// 是否可关闭
						})
						break;
					case 401:
						Message({
							message: "登录过期，已为您跳转到登录页！",
							type: "error",
							showClose: true
						})
						localStorage.removeItem("token")
						location.reload()
						break;
					case 500:
						Message({
							message: "服务器异常，请稍后再试！",
							type: "error",
							showClose: true
						})
						break;
					default: //不在上述范围内的错误处理
						Message({
							message: "未知错误！",
							type: "error",
							showClose: true
						})
				}
			}
			
			reject(err) //新Promise对象实例（失败）
		})
	})
}
// 返回值 提示码
function successState(res) {
	res.data.code = String(res.data.code)
    if (res.status == 200) {
        if (res.data.code[0] == '2') {  //请求成功状态码
            Notification({
                message: res.data.message,
                type: "success",        //指定success弹框
                showClose: true
            })
            return
        } else if (res.data.code[0] == '3') { //请求失败状态码
            Notification({
                message: res.data.message,
                type: "error",          //指定error弹框
                showClose: true
            })
            return
        }
    }
}
// 封装axios 文件上传
export function PostFile(file,name) { 
	return new Promise((resolve, reject) => {
		let formData = new FormData();
		formData.append("file", file)
		formData.append("name", name)
		let config = {headers: {"Content-Type": "multipart/form-data"}}
		resolve(axios.post('https://egf.cn/dsviupvid.php', formData, config).then(res=>{
			console.log(res.data)
			successState(res)
			if(res.data.code == 200){
				res.data.data = 'https://egf.cn/'+res.data.data
			}
			return res
		}))
	})
}


// 封装axios 文件上传
export function PostEditorFile(file,name) { 
	return new Promise((resolve, reject) => {
		let formData = new FormData();
		formData.append("file", file)
		formData.append("name", name)
		let config = {headers: {"Content-Type": "multipart/form-data"}}
		resolve(axios.post(api.ServerEditorFile, formData, config).then(res=>{
			successState(res)
			return res
		}))
	})
}

// Icon
export function ServerIconFile(file,name) { 
	return new Promise((resolve, reject) => {
		let formData = new FormData();
		formData.append("file", file)
		formData.append("name", name)
		let config = {headers: {"Content-Type": "multipart/form-data"}}
		resolve(axios.post(api.ServerIconFile, formData, config).then(res=>{
			successState(res)
			return res
		}))
	})
}
