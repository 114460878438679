// 数据仓库
import Vue from 'vue'
import Vuex from 'vuex'
import {MessageBox,Message,Loading} from 'element-ui'
import router, {resetRouter} from '@/router'
import { post } from '@/utils/request'
import { verifyPhone } from '@/utils/fun'
const API = require('@/utils/api');					// 全局接口配置
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
	 // 后台
	 CourseTab:'issue',								// 课程管理Tab
	 BlogTab:'issue',								// Blog管理Tab
	 createstate:1,									// 创建课程流程
	 ruleForm: {
	 	coursename: '', 							// 课程名称
	 	courseteacher: '', 							// 授课老师
	 	coursecategory: '', 						// 课程类别 
	 	courseintroduce: '', 						// 课程介绍
	 	coverimg: '', 								// 课程海报
	 	covimg: {url: ''}, 							// 覆盖ID
	 	courseimg: '', 								// 课程海报
	 	delimg: {url: ''}, 							// 覆盖ID
	 	paycourse: false, 							// 是否付费开关	
	 	pricecourse: '', 							// 课程价格--在切换成付费课后显示
	 	type: '' 									// 权限组
	 }, 
	 BlogForm: {  
	 	Blogname: '', 								// 课程名称
	 	category: [], 								// 标签 
	 	coverimg: '', 								// 课程海报
		sourceUr: '',								// 视频源
		Playname:'',								// 视频原名
		name:'',									// OSS名称
		timer:null,									// 视频时长
		file:'',									// 视频源
	 },
	 newcourse: {}, 								// 新的课程
	 coursechild: [],								// 章 //节
	 allshowcorser:true,							// 课程列表开关
	 allcourses:[],									// 发布的课程
	 allcourdernei:[],								// 章节
	 
	 // 用户端
	 islogin:false,									// 是否登录
	 query:'',										// 全局搜索
	 Height:document.documentElement.clientHeight,  // 全局屏幕高度
	 LoginShow:false,								// 登录窗口
	 selected:1,									// 当前页面
	 userinfo:{},									// 用户信息
	 learns:'',										// 学习时长
	 mycuorse:[],									// 我的收藏
	 other:{},      								// 用户其他信息
	 address:[],    								// 存储用户收件地址
	 playerSource:{},								// 播放器预播放源--课程
	 playSoutce:{},									// 博客播放源
	 coursecontentsay:[],							// 评论
	 playerduring:{},								// 播放中的源
	 PlayerShow:true,								// 用于重新加载 
	 blog:[],										// 博客列表
	 favor:{},										// 收藏跳转参数
	 searchB:{},									// 搜索跳转参数
	 linepay:false,									// 充值弹窗 在线充值
	 recordPlay:null,								// 视频上次播放记录
	 recordPlayBlog:null,							// Blog上次播放记录
	 NumberParticipants:0,							// 课程学习人数
	 ispermis:false,								// 课程购买按钮 显示
	 CategoryData:[],								// 分类列表
  },
  mutations: {
  },
  actions: {
	  /*
		后台
	  */ 
	  
	  // 创建新课程
	  createcourse({state}){
	  	startLoading('加载中..')  
	  	return new Promise((resolve, reject) => {
				let obj = {
					coursename: state.ruleForm.coursename,
					courseteacher: state.ruleForm.courseteacher,
					coursecategory: state.ruleForm.coursecategory,
					courseintroduce: state.ruleForm.courseintroduce,
					coverimg: state.ruleForm.coverimg,
					courseimg: state.ruleForm.courseimg,
					pricecourse:state.ruleForm.paycourse == true? state.ruleForm.pricecourse:state.ruleForm.type,
					paycourse:state.ruleForm.paycourse
				}
	  			post({url: API.createcourse,data:obj,IS: true})
	  			.then(response => {
	  				endLoading()
	  				resolve(response.data)
	  			}) 
	  		})
	  },
	  // 章节入库
	  PutSection({state},e){ 
	  	startLoading('加载中...')
	  	return new Promise((resolve, reject) => {
	  		post({url: API.PutSection,data:e,IS: false})
	  		.then(response => {
	  			resolve(response.data)
	  			 endLoading()
	  		})  
	  	})
	  },
	  // 课程列表
	  GetCourses({state}){ 
	  	startLoading('加载中...')
	  	return new Promise((resolve, reject) => {
	  		post({url: API.GetCourses,data:{},IS: false})
	  		.then(response => {
	  			resolve(response.data)
	  			 endLoading()
	  		})  
	  	})
	  },
	  // 更新课程信息
	  UpCourses({state},e){ 
	  	startLoading('加载中...')
	  	return new Promise((resolve, reject) => {
	  		post({url: API.UpCourses,data:e,IS: true})
	  		.then(response => {
	  			resolve(response.data)
	  			 endLoading()
	  		})  
	  	})
	  },
	  // 课程列表
	  GetSection({state},e){ 
	  	startLoading('加载中...')
	  	return new Promise((resolve, reject) => {
	  		post({url: API.GetSection,data:e,IS: false})
	  		.then(response => {
	  			resolve(response.data)
	  			 endLoading()
	  		})  
	  	})
	  },
	  // 章节更新
	  UpSection({state},e){ 
	  	startLoading('加载中...')
	  	return new Promise((resolve, reject) => {
	  		post({url: API.UpSection,data:e,IS: true})
	  		.then(response => {
	  			resolve(response.data)
	  			 endLoading()
	  		})  
	  	})
	  },
	  // 章节新增
	  AddSection({state},e){ 
	  	startLoading('加载中...')
	  	return new Promise((resolve, reject) => {
	  		post({url: API.AddSection,data:e,IS: true})
	  		.then(response => {
	  			resolve(response.data)
	  			 endLoading()
	  		})  
	  	})
	  },
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  
	  // 用户端
	  // 验证码登录
	  Login({state},e) {
		  startLoading('正在登陆！')  
	  	return new Promise((resolve, reject) => {
	  		post({url: API.Login,data:e,IS: true})
	  		.then(response => {
				setTimeout(() => {
					endLoading()
					resolve(response.data)
				}, 500);
				
	  	    });
	  	})
	  },
	  // 验证码验证
	  RegCode({state},e){ 
		startLoading('正在向'+e+'手机号码发送验证码，请注意查收！')  
	  	return new Promise((resolve, reject) => {
			if(verifyPhone(e)){
				post({url: API.RegCode,data:{phone:e},IS: true})
				.then(response => {
					setTimeout(() => {
						endLoading()
						resolve(response.data)
					}, 500);
				})  
			}else{
				Message.warning('请输入正确的手机号码')
			}
	  	})
	  },
	  // 校验登录
	  verify({state},e){
	  	return new Promise((resolve, reject) => {
			post({url: API.verify,data:e,IS: false})
			.then(response => {
					resolve(response.data)
			}) 
		})
	  },
	  // 小程序二维码登录	
	  WeChatQR({state}){
		  startLoading('加载中..')  
	  	return new Promise((resolve, reject) => {
			post({url: API.WeChatQR,data:{},IS: false})
			.then(response => {
				endLoading()
				resolve(response.data)
			}) 
		})
	  },
	  //  校验是否扫码	
	  verifyQR({state},e){
	  	return new Promise((resolve, reject) => {
	  			post({url: API.verifyQR,data:e,IS: false})
	  			.then(response => {
	  				resolve(response)
	  			}) 
	  		})
	  },
	  // 获取用户额外数据
	  OTHERINFO({state,commit}) {
		  post({url: API.otherinfo,data:{uid:state.userinfo.id,inv:state.userinfo.beinvited},IS: false}).then(response => {
			  // 更新用户信息
			  state.userinfo = response.data.data[4]
			  localStorage.setItem('userinfo', JSON.stringify(response.data.data[4]));
			  // 地址
			  sessionStorage.setItem('address', JSON.stringify(response.data.data[1]));
			  state.address = response.data.data[1]
			  // 积分
			  sessionStorage.setItem('other', JSON.stringify(response.data.data[0]));
			  state.other =  response.data.data[0] 
			  // 我的收藏
			  response.data.data[3].forEach(item=>{
				  state.mycuorse.push(item)
			  })
			  // 收藏去重
			  state.mycuorse = Array.from(new Set(state.mycuorse.map(JSON.stringify))).map(JSON.parse);
		  })
	  },
	  //  获取课程列表
	  allcourse({state},e){ 
	  	return new Promise((resolve, reject) => {
	  			post({url: API.allcourse,data:e,IS: false})
	  			.then(response => {
	  				resolve(response.data)
	  			}) 
	  		})
	  },
	  // 获取课程信息
	  courseInfo({state},e){
	  	return new Promise((resolve, reject) => {
	  			post({url: API.courseInfo,data:e,IS: false})
	  			.then(response => {
	  				resolve(response.data)
	  			}) 
	  		})
	  },
	  //  获取课程列表
	  allblog({state}){
	  	return new Promise((resolve, reject) => {
	  			post({url: API.allblog,data:{},IS: false})
	  			.then(response => {
	  				resolve(response.data)
	  			}) 
	  		})
	  },
	  // 添加到收藏夹
	  addCollect({state},e){
	  	return new Promise((resolve, reject) => {
	  			post({url: API.addCollect,data:e,IS: true})
	  			.then(response => {
	  				resolve(response.data)
	  			}) 
	  		})
	  },
	  // 移出收藏夹
	  delCollect({state},e){
	  	return new Promise((resolve, reject) => {
	  			post({url: API.delCollect,data:e,IS: true})
	  			.then(response => {
	  				resolve(response.data)
	  			}) 
	  		})
	  },
	  // 我的收藏夹
	  Collect({state},e){
	  	return new Promise((resolve, reject) => {
	  			post({url: API.Collect,data:e,IS: false})
	  			.then(response => {
	  				resolve(response.data)
	  			}) 
	  		})
	  },
	  // 修改用户名称
	  ModifyUser({state},e){
	  	return new Promise((resolve, reject) => {
	  			post({url: API.ModifyUser,data:e,IS: true})
	  			.then(response => {
	  				resolve(response.data)
	  			}) 
	  		})
	  },
	  // 流水细明
	  Balance({state},e){
	  	return new Promise((resolve, reject) => {
	  			post({url: API.Balance,data:e,IS: false})
	  			.then(response => {
	  				resolve(response.data)
	  			}) 
	  		})
	  },
	  // 系统充值
	  WxAddQr({state},e){ 
	  	startLoading('加载中...')
	  	return new Promise((resolve, reject) => {
	  		post({url: API.WxAddQr,data:e,IS: false})
	  		.then(response => {
	  			resolve(response.data)
	  			 endLoading()
	  		})  
	  	})
	  },
	  // 支付监听
	  PayListen({state},e){
	  	startLoading('加载中...')
	  	return new Promise((resolve, reject) => {
	  		post({url: API.PayListen,data:e,IS: false})
	  		.then(response => {
	  			resolve(response.data)
	  			 endLoading()
	  		})  
	  	})
	  },
	  // 学员认证--线上学员
	  PayMember({state},e){
	  	startLoading('认证中...')
	  	return new Promise((resolve, reject) => {
	  		post({url: API.PayMember,data:e,IS: true})
	  		.then(response => {
	  			resolve(response.data)
	  			 endLoading()
	  		})  
	  	})
	  },
	  // 学员认证--线上学员
	  DelOrder({state},e){
	  	startLoading('认证中...')
	  	return new Promise((resolve, reject) => {
	  		post({url: API.DelOrder,data:e,IS: false})
	  		.then(response => {
	  			resolve(response.data)
	  			 endLoading()
	  		})  
	  	})
	  },
	  // 课程鉴权
	  authCourse({state},e){
	  	return new Promise((resolve, reject) => {
	  		post({url: API.authCourse,data:e,IS: false})
	  		.then(response => {
	  			resolve(response.data)
	  		})  
	  	})
	  },
	  // 观看课程鉴权+学时记录
	  authCourseIng({state},e){
	  	return new Promise((resolve, reject) => {
	  		post({url: API.authCourseIng,data:e,IS: false})
	  		.then(response => {
	  			resolve(response.data)
	  		})  
	  	})
	  },
	  // 获取播放历史记录
	  GetMyLogPlay({state},e){
	  	return new Promise((resolve, reject) => {
	  		post({url: API.GetMyLogPlay,data:e,IS: false})
	  		.then(response => {
	  			resolve(response.data)
	  		})  
	  	})
	  },
	  // 删除 历史记录
	  DelMyLogPlay({state},e){
	  	return new Promise((resolve, reject) => {
	  		post({url: API.DelMyLogPlay,data:e,IS: true})
	  		.then(response => {
	  			resolve(response.data)
	  		})  
	  	})
	  },
	  // 获取我的课程
	  GetMyCourses({state}){
	  	return new Promise((resolve, reject) => {
	  		post({url: API.GetMyCourses,data:{uid:state.userinfo.id},IS: false})
	  		.then(response => {
	  			resolve(response.data)
	  		})  
	  	})
	  },
	  // 获取所有用户信息
	  GetUserAll({state}){
	  	return new Promise((resolve, reject) => {
	  		post({url: API.GetUserAll,data:{},IS: false})
	  		.then(response => {
	  			resolve(response.data)
	  		})  
	  	})
	  },
	  // 用户信息
	  GetUser({state},e){
	  	return new Promise((resolve, reject) => {
	  		post({url: API.GetUser,data:e,IS: false})
	  		.then(response => {
	  			resolve(response.data)
	  		})  
	  	})
	  },
	  // Blog 发布
	  PutBlog({state},e){
	  	return new Promise((resolve, reject) => {
	  		post({url: API.PutBlog,data:e,IS: true})
	  		.then(response => {
	  			resolve(response.data)
	  		})  
	  	})
	  },
	  // Blog 发布
	  GetBlogAll({state}){
	  	return new Promise((resolve, reject) => {
	  		post({url: API.GetBlogAll,data:{},IS: false})
	  		.then(response => {
	  			resolve(response.data)
	  		})  
	  	})
	  },
	  // Blog 更新
	  ModifyBlog({state},e){
	  	return new Promise((resolve, reject) => {
	  		post({url: API.ModifyBlog,data:e,IS: true})
	  		.then(response => {
	  			resolve(response.data)
	  		})  
	  	})
	  },
	  // Blog 更新播放量
	  AmountPlay({state},e){
	  	return new Promise((resolve, reject) => {
	  		post({url: API.AmountPlay,data:e,IS: false})
	  		.then(response => {
	  			resolve(response.data)
	  		})  
	  	})
	  },
	  // 发表评论
	  DiscussSay({state},e){
	  	return new Promise((resolve, reject) => {
	  		post({url: API.DiscussSay,data:e,IS: false})
	  		.then(response => {
	  			resolve(response.data)
	  		})  
	  	})
	  },
	  // 课程分类列表
	  GetCategoryList({state}){
		
	  	return new Promise((resolve, reject) => {
	  		post({url: API.GetCategoryList,data:{},IS: false})
	  		.then(response => {
				
	  			resolve(response.data)
	  		})  
	  	})
	  },
	  //  修改新增、课程分类
	  ModifyCategory({state},e){
	  	return new Promise((resolve, reject) => {
	  		post({url: API.ModifyCategory,data:e,IS: true})
	  		.then(response => {
	  			resolve(response.data)
	  		})  
	  	})
	  },
	  //  获取指定分类的课程
	  GetcourseCla({state},e){
	  	return new Promise((resolve, reject) => {
	  		post({url: API.GetcourseCla,data:e,IS: false})
	  		.then(response => {
	  			resolve(response.data)
	  		})  
	  	})
	  },
	  //  全局搜索
	  AllSearch({state},e){
	  	return new Promise((resolve, reject) => {
	  		post({url: API.AllSearch,data:e,IS: false})
	  		.then(response => {
				setTimeout(() =>{
					resolve(response.data)
				},300);
	  		})  
	  	})
	  },
	  
  },
  // 抛出 state仓库
  getters: {
		states: state => state
  }
})


let loading;
//定义加载动画
function startLoading(e) {
	loading = Loading.service({
		lock: true, //是否锁定
		text: e, //显示在加载图标下方的加载文案
		background: 'rgba(0,0,0,.5)', //遮罩背景色
	});
}
//结束加载动画，调用该实例的 close 方法来关闭
function endLoading() {
	loading.close();
}
