import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)



// 首页
const Home =  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home/index'),
	meta: {
		title: '首页',
	}
  }

// 首页
const al =  {
    path: '/al',
    name: 'al',
    component: () => import('@/views/al/index'),
	meta: {
		title: '案例处理',
	}
  }


const routes = [
	Home,
	al
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
